import React from 'react'
import { Helmet } from 'react-helmet';

import About from '../components/About';
import Hero from '../components/Hero';
import Services from '../components/Services';

const Home = () => {
  return (
    <>
        <Helmet>
          <title>SPEEDLEX - Transport persoane , colete , auto</title>
          <meta name='description' content='Transport persoane, colete, auto in Germania, Romania, Austria...' />
          <meta name='keywords' content='transport persoane, colete, auto' />
          <meta name="author" content="Host2Go" />
        </Helmet>
        <Hero />
        <Services />
        <About />
    </>
  )
}

export default Home