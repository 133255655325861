import React, {useState} from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "../translations/i18n.js";

const ServicesScreen = () => {

  const [selected, setSelected] = useState(0);
  const { t } = useTranslation();

  return (
    <div className='max-w-5xl py-[8rem] mx-auto text-black'>
        <Helmet>
          <title>SPEEDLEX - Transport persoane, colete, auto - Servicii</title>
          <meta name='description' content='Any questions or remarks? Just write us a message!' />
          <meta name='keywords' content='transport persoane colete auto' />
          <meta name="author" content="Host2Go" />
        </Helmet>
        <div className='card w-full shadow-[0_0px_80px_2px_rgba(0,0,0,0.1)] flex flex-col md:flex-row bg-white'>
            <div className='md:w-[40%] flex flex-row md:flex-col md:ml-4'>
                <button className='sw' onClick={() => setSelected(0)}>
                    <div className={'pt-[5.6rem] md:border-l-4 border-t-4 md:border-t-0 border-primary py-[4rem] md:py-[7rem] flex justify-center hover:text-white rounded-md rounded-tr-none md:rounded-t-md md:rounded-r-none md:rounded-bl-none ' + (selected === 0 ? 'bg-[#258bff] text-white' : 'transa')}>
                        <h1 className='font-medium text-xl'>{t("serv1")}</h1>
                    </div>
                </button>
                <button className='sw' onClick={() => setSelected(1)}>
                    <div className={'md:border-l-4 border-t-4 md:border-t-0 border-orange-600 py-[4rem] md:py-[7rem] flex justify-center rounded-b-md md:rounded-none md:rounded-b-none ' + (selected === 1 ? ' text-white bg-[#ec6627]' : ' hover:text-white trans')}>
                        <h1 className='font-medium text-xl'>{t("serv2")}</h1>
                    </div>
                </button>
                <button className='sw' onClick={() => setSelected(2)}>
                    <div className={'md:border-l-4 border-t-4 md:border-t-0 border-cyan-500 py-[4rem] md:py-[7rem] flex justify-center rounded-md rounded-tl-none md:rounded-b-md md:rounded-r-none ' + (selected === 2 ? 'text-white bg-[#22D3EE] ' : 'hover:text-white transb')}>
                        <h1 className='font-medium text-xl'>{t("serv3")}</h1>
                    </div>
                </button>
            </div>
            <div className='md:w-[60%] mr-4'>
                { selected === 0 ?
                <div>
                    <div className='flex flex-col items-center'>
                        <h1 className='font-medium text-3xl border-b-2 pb-2 text-center w-[30%] mt-[3.5rem]'>{t("serv1")}</h1>
                        <p className='text-xl opacity-75 pt-6'>{t("provide")}</p>
                    </div>
                    <div className='ml-6 md:ml-10 pt-[2rem] md:mb-[24rem]'>
                        <div className='flex flex-col gap-1 mb-2'>
                            <h1 className='border-b-2 hover:border-primary font-medium w-fit text-xl'>{t("wdt1")}</h1>
                            <p className='opacity-75 ml-2'>&#x2022; {t("unique")}</p>
                            <p className='opacity-75 ml-2'>&#x2022; {t("seo")}</p>
                        </div>
                    </div>
                </div>
                :
                selected === 1 ?
                <div>
                    <div className='flex flex-col items-center'>
                        <h1 className='font-medium text-3xl border-b-2 pb-2 text-center w-[30%] mt-[3.5rem]'>{t("serv2")}</h1>
                        <p className='text-xl opacity-75 pt-6'>{t("provide")}</p>
                    </div>
                    <div className='ml-6 md:ml-10 pt-[2rem] md:mb-[22rem]'>
                        <div className='flex flex-col gap-1 mb-2'>
                        <h1 className='border-b-2 hover:border-primary font-medium w-fit text-xl'>{t("wdt1")}</h1>
                            <p className='opacity-75 ml-2'>&#x2022; {t("unique")}</p>
                            <p className='opacity-75 ml-2'>&#x2022; {t("seo")}</p>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className='flex flex-col items-center'>
                        <h1 className='font-medium text-3xl border-b-2 pb-2 text-center w-[30%] mt-[3.5rem]'>{t("serv3")}</h1>
                        <p className='text-xl opacity-75 pt-6'>{t("provide")}</p>
                    </div>
                    <div className='ml-6 md:ml-10 pt-[2rem] md:mb-[24rem]'>
                        <div className='flex flex-col gap-1 mb-2'>
                        <h1 className='border-b-2 hover:border-primary font-medium w-fit text-xl'>{t("wdt1")}</h1>
                            <p className='opacity-75 ml-2'>&#x2022; {t("unique")}</p>
                            <p className='opacity-75 ml-2'>&#x2022; {t("seo")}</p>
                        </div>
                    </div>
                </div>
                }
                <Link to={'/contact'}><div className='mt-10 flex justify-center btn btn-primary text-white ml-4'><button>{t("touch")}</button></div></Link>
            </div>
        </div>
    </div>
  )
}

export default ServicesScreen