import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "../translations/i18n.js";

import Photo from '../assets/interior.jpg';
import PhotoTwo from '../assets/second.jpg';

const About = () => {
  const { t } = useTranslation();
  return (
    <>
        {/* <div className='shadow-[0_0px_60px_2px_rgba(0,0,0,0.1)] rounded-xl mt-[10rem] py-6 md:w-[66%] md:mx-auto bg-white'>
            <h1 className='text-center text-2xl font-medium mb-8'>{t("tech1")}</h1>
            <div className='grid grid-cols-2 items-center md:grid-cols-3 md:px-20 text-xl gap-10'>
              <div className='flex justify-center'><FaReact className='text-6xl' /></div>
              <div className='flex justify-center'><SiMysql className='text-6xl' /></div>
              <div className='flex justify-center'><SiFlutter className='text-6xl' /></div>
              <div className='flex justify-center'><TbBrandReactNative className='text-6xl' /></div>
              <div className='flex justify-center'><SiPhp className='text-6xl' /></div>
              <div className='flex justify-center'><SiWordpress className='text-6xl' /></div>
            </div>
            <h1 className='text-xl text-center mt-8'>{t("tech2")}</h1>
        </div> */}
        <div className="hero min-h-screen text-black">
            <div className="hero-content flex-col lg:flex-row">
                {/* <img src={One} alt="hero" className="max-w-sm md:max-w-md rounded-lg shadow-2xl bg-white" /> */}
                <div className="carousel w-full h-[400px] rounded-md">
                  <div id="slide1" className="carousel-item relative w-full">
                    <img
                      alt='1'
                      src={Photo}
                      className="w-full" />
                    <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                      <a href="#slide4" className="btn btn-circle">❮</a>
                      <a href="#slide2" className="btn btn-circle">❯</a>
                    </div>
                  </div>
                  <div id="slide2" className="carousel-item relative w-full">
                    <img
                      alt='2'
                      src={PhotoTwo}
                      className="w-full" />
                    <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                      <a href="#slide1" className="btn btn-circle">❮</a>
                      <a href="#slide3" className="btn btn-circle">❯</a>
                    </div>
                  </div>
                  <div id="slide3" className="carousel-item relative w-full">
                    <img
                      alt='3'
                      src={Photo}
                      className="w-full" />
                    <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                      <a href="#slide2" className="btn btn-circle">❮</a>
                      <a href="#slide4" className="btn btn-circle">❯</a>
                    </div>
                  </div>
                  <div id="slide4" className="carousel-item relative w-full">
                    <img
                      alt='4'
                      src={PhotoTwo}
                      className="w-full" />
                    <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                      <a href="#slide3" className="btn btn-circle">❮</a>
                      <a href="#slide1" className="btn btn-circle">❯</a>
                    </div>
                  </div>
                </div>
                <div className='text-right max-w-2xl'>
                  <h1 className="text-5xl md:text-8xl font-bold">{t("about1")} <span className='text-primary'>{t("aboutblue")}</span> {t("about2")}</h1>
                  <p className="py-6 md:ml-4 opacity-75">{t("aboutsub")}</p>
                  <Link to={'/services'}><button className="btn btn-primary text-white rounded-full w-[10rem]">{t("aboutbutt")}</button></Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default About