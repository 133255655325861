export const TRANSLATIONS_RO = {
  herotitle1:"Firma de transport",
  herotitleblue:"SPEEDLEX TOUR.",
  herotitle2:"",
  herosub:"Firma de transport persoane SPEEDLEX va ofera saptamanal curse regulate cu plecare din Romania catre Germania, Belgia sau Olanda cu traseul prin Ungaria – Austria sau Cehia. Linia internationala de transport persoane si colete SPEEDLEX este asigurata de microbuze moderne 8+1 locuri prin curse saptamanale efectuate in cele mai bune conditii de confort si siguranta la preturi aceesibile tuturor.",
  herobutt:"REZERVA O CURSA - 0784 436 887",
  service1:"Plecarile au loc in fiecare saptamana de pe aproximativ tot teritoriul Romaniei.",
  service2:"Copii sub 2 ani beneficiaza de gratuitate la transportul cu firma noastra.",
  service3:"Transportul se realizeaza de la adresa la adresa atat din tara cat si din Germania.",
  servicebutt:"AFLĂ MAI MULTE",
  service:"De ce sa ne alegi pe noi?",
  tech1:"Tehnologii cu care lucrăm",
  tech2:"și multe altele...",
  about1:"Cine este",
  aboutblue:"SPEEDLEX",
  about2:"si cu ce ne ocupam?",
  aboutsub:"Oferim transport de persoane in conditii VIP, cu microbuze de ultima generatie la preturi normale sau chiar sub pretul pe care il ofera concurenta. Alaturi de firma SPEEDLEX aveti parte de toata increderea in vederea transportului international la destinatie. Ne propunem sa deveniti clientii nostri fideli si sa ne priviti ca pe un partener de incredere pentru calatoriile dumneavoastra asa cum ne considera si alte sute de clienti.",
  aboutbutt:"ÎNCEPE ACUM",
  contacttitle:"Contacteaza-ne",
  contactsub:"Întrebări sau observații? Scrie-ne și îți vom răspunde cât mai repede posibil!",
  cinfo:"Informații",
  formback:"Completează formularul și echipa noastră te va contacta înapoi în maxim 24 de ore.",
  fname:"Prenume",
  lname:"Nume",
  phone:"Telefon",
  product:"Ce serviciu te-ar interesa?",
  message:"Spune-ne cu ce te putem ajuta..",
  send:"TRIMITE",
  provide:"Ce îți putem oferii:",
  wdt1:"Plecari saptamanale cu microbuze moderne",
  seo:"Soferi profesionisti si cu experienta.",
  unique:"Date flexibile de plecare și sosire.",
  touch:"Rezerva o cursa - 0784 436 887",
  hireus:"Cere o ofertă!",
  home:"Acasă",
  serv:"Servicii",
  serv1:"Plecari saptamanale",
  serv2:"Reduceri transport copii",
  serv3:"Transport la adresa" 
};