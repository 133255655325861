import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Contact from './screens/Contact';
import Cookie from './screens/Cookie';
import Home from './screens/Home';
import Privacy from './screens/Privacy';
import ServicesScreen from "./screens/ServicesScreen";
import Terms from './screens/Terms';

import Wap from './assets/wapp.png';

function App() {
  return (
    <>
    <Router>
      <div className="app-layout">
        <header>
          <Navbar />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/cookie" element={<Cookie />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<ServicesScreen />} />
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
        <div className='fixed bottom-0 right-0 p-4'>
          <a href='https://wa.me/+40784436887' target={"_blank"} rel="noopener noreferrer">
            <img src={Wap} alt="whatsapp" width={80} height={80} />
          </a>
        </div>
      </div>
    </Router>
    </>
  );
}

export default App;
