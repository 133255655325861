import React from 'react'
import { Link } from 'react-router-dom';
import {FaInstagram, FaWhatsapp, FaFacebook} from "react-icons/fa"

import FLogo from '../assets/logo.png'

const Footer = () => {
  return (
    <footer className="footer p-10 bg-white text-base-content shadow-[0_-10px_40px_2px_rgba(0,0,0,0.1)]">
        <div>
            <img src={FLogo} alt="logo" width={50} height={50} />
            <p>SPEED LEX TRANSPORT S.R.L.<br/>Providing reliable transport services</p>
            <p>POWERED BY <a href='https://host2go.co.uk/'>host2go</a></p>
        </div> 
        <div>
            <span className="footer-title">SPEEDLEX</span> 
            <div className='flex gap-4'>
                <a href="https://www.instagram.com/speedlexcourier?igsh=MTVlanZ0aHI3Z3lzbg==" target={"_blank"} rel="noopener noreferrer"><FaInstagram className='w-8 h-8' /></a>
                <a href="https://www.facebook.com/profile.php?id=61562032166757" target={"_blank"} rel="noopener noreferrer"><FaFacebook className='w-8 h-8' /></a>
                <a href="https://wa.me/+40784436887" target={"_blank"} rel="noopener noreferrer"><FaWhatsapp className='w-8 h-8' /></a>
            </div>
        </div> 
        <div>
            <span className="footer-title">Legal</span> 
            <Link to={'/terms'}>Terms of use</Link>
            <Link to={'/privacy'}>Privacy policy</Link>
            <Link to={'/cookie'}>Cookie policy</Link>
        </div>
        <div>
            <span className="footer-title">Contact</span> 
            <button><a href='mailto:office@speedlex.ro'>Send us an mail</a></button> 
            <a href='tel:+40784436887' target={"_blank"} rel="noopener noreferrer">Suna-ne</a> 
            <button>Telefon: 0784436887</button> 
        </div> 
        </footer>
  )
}

export default Footer