import React from 'react'
import Microbuz from '../utils/microbuze.png'
import { useTranslation } from "react-i18next";

import { FaRoute } from "react-icons/fa";
import { BsPeople } from "react-icons/bs";
import { PiVan } from "react-icons/pi";

import "../translations/i18n.js";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <>
        <div className="hero min-h-screen text-black mb-8 md:mb-0">
            <div className="hero-content flex-col mt-4 lg:mt-0 lg:flex-row-reverse gap-4">
                <img src={Microbuz} alt="hero" className="max-w-[280px] md:max-w-md md:min-h-[600px] rounded-lg shadow-2xl mt-14 mb-8 md:mb-0 md:mt-0 bg-white p-4 object-contain" />
                <div>
                    <h1 className="text-6xl md:text-8xl font-bold">{t("herotitle1")} <span className='text-primary'>{t("herotitleblue")}</span> {t("herotitle2")}</h1>
                    <p className="py-6 opacity-75 md:mr-4">{t("herosub")}</p>
                    <a href='tel:+40784436887' target={"_blank"} rel="noopener noreferrer"><button className="btn btn-primary text-white rounded-full">{t("herobutt")}</button></a>
                </div>
            </div>
        </div>
        <div className="stats shadow-[0_0px_80px_2px_rgba(0,0,0,0.1)] flex flex-col md:flex-row justify-center md:w-[66%] md:mx-auto">
            <div className="stat">
                <div className="stat-figure text-secondary">
                    <PiVan className='h-8 w-8' />
                </div>
                <div className="stat-title">Curse</div>
                <div className="stat-value">10+</div>
            </div>
            <div className="stat">
                <div className="stat-figure text-secondary">
                    <BsPeople className='h-8 w-8' />
                </div>
                <div className="stat-title">Clienti multumiti</div>
                <div className="stat-value">10+</div>
            </div>
            <div className="stat">
                <div className="stat-figure text-secondary">
                    <FaRoute className='h-8 w-8' />
                </div>
                <div className="stat-title">Rute</div>
                <div className="stat-value">8</div>
            </div>
            
        </div>
    </>
  )
}

export default Hero